function VectorIcon() {
  return (
    <div>
      <svg
        width="22"
        height="20"
        viewBox="0 0 22 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.7923 9.93291C4.50481 9.59741 4.54321 9.09241 4.87871 8.80491C5.21521 8.51741 5.71921 8.55642 6.00671 8.89132L7.60671 10.758C7.86371 11.0577 7.86371 11.4998 7.60671 11.7996L6.00671 13.6662C5.84831 13.8508 5.62481 13.9452 5.39921 13.9452C5.21471 13.9452 5.02961 13.8822 4.87871 13.7526C4.54321 13.4652 4.50481 12.9601 4.7923 12.6246L5.94591 11.2785L4.7923 9.93291ZM9.66641 12.8785C9.66641 12.4369 10.0248 12.0785 10.4664 12.0785H13.9331C14.3747 12.0785 14.7331 12.4369 14.7331 12.8785C14.7331 13.3206 14.3747 13.6785 13.9331 13.6785H10.4664C10.0248 13.6785 9.66641 13.3206 9.66641 12.8785ZM9.66641 9.67852C9.66641 9.23692 10.0248 8.87852 10.4664 8.87852H16.0664C16.508 8.87852 16.8664 9.23692 16.8664 9.67852C16.8664 10.1206 16.508 10.4785 16.0664 10.4785H10.4664C10.0248 10.4785 9.66641 10.1206 9.66641 9.67852ZM1.66641 17.4118H20.3331V5.67851H1.66641V17.4118ZM1.66641 4.07852H4.86641V1.94522H1.66641V4.07852ZM6.46641 4.07852H20.3331V1.94522H6.46641V4.07852ZM20.8664 0.345215H1.13311C0.543706 0.345215 0.0664062 0.823016 0.0664062 1.41182V17.9452C0.0664062 18.5345 0.543706 19.0118 1.13311 19.0118H20.8664C21.4552 19.0118 21.9331 18.5345 21.9331 17.9452V1.41182C21.9331 0.823016 21.4552 0.345215 20.8664 0.345215Z"
          fill="white"
        />
      </svg>
    </div>
  );
}

export default VectorIcon;
