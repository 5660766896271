function VectorIcon() {
  return (
    <div>
      <svg
        width="22"
        height="23"
        viewBox="0 0 22 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.3107 15.9657C15.6163 15.6468 15.6056 15.1401 15.2867 14.8345L11.8051 11.5945L12.2472 6.41316C12.2851 5.97316 11.9587 5.58596 11.5187 5.54806C11.0755 5.51126 10.6909 5.83716 10.6536 6.27716L10.1784 11.8398C10.1576 12.0804 10.2472 12.3177 10.4216 12.4852L14.1795 15.9897C14.3347 16.138 14.5341 16.2121 14.7331 16.2121C14.9437 16.2121 15.1533 16.1294 15.3107 15.9657ZM21.9331 11.6788C21.9331 17.7054 17.0264 22.6121 10.9997 22.6121C4.97311 22.6121 0.0664062 17.7054 0.0664062 11.6788C0.0664062 11.2361 0.423705 10.8788 0.866405 10.8788C1.30911 10.8788 1.66641 11.2361 1.66641 11.6788C1.66641 16.8254 5.85311 21.0121 10.9997 21.0121C16.1464 21.0121 20.3331 16.8254 20.3331 11.6788C20.3331 6.53206 16.1464 2.34536 10.9997 2.34536C8.05041 2.34536 5.26641 3.76406 3.52241 6.10536H6.19971C6.64241 6.10536 6.99971 6.46276 6.99971 6.90536C6.99971 7.34806 6.64241 7.70536 6.19971 7.70536H1.93311C1.49041 7.70536 1.13311 7.34806 1.13311 6.90536V2.63876C1.13311 2.19606 1.49041 1.83876 1.93311 1.83876C2.37571 1.83876 2.73311 2.19606 2.73311 2.63876V4.53736C4.78111 2.16406 7.81041 0.745361 10.9997 0.745361C17.0264 0.745361 21.9331 5.65206 21.9331 11.6788Z"
          fill="white"
        />
      </svg>
    </div>
  );
}

export default VectorIcon;
