function VectorIcon() {
  return (
    <div>
      <svg
        width="15"
        height="23"
        viewBox="0 0 15 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.92306 15.2805C1.85056 14.8448 1.43726 14.5488 1.00256 14.6229C0.566858 14.6955 0.272457 15.1077 0.344957 15.5435L1.41166 21.9435C1.47666 22.3349 1.81586 22.6123 2.19986 22.6123C2.24366 22.6123 2.28786 22.6085 2.33216 22.6011C2.76786 22.5285 3.06226 22.1163 2.98976 21.6805L1.92306 15.2805ZM13.5397 11.9579C13.1066 11.8811 12.6895 12.1712 12.6127 12.6064L11.0127 21.6731C10.9359 22.1083 11.2266 22.5232 11.6618 22.6C11.7087 22.608 11.7557 22.6123 11.8015 22.6123C12.1823 22.6123 12.5199 22.3392 12.5887 21.9509L14.1887 12.8843C14.2655 12.4491 13.9749 12.0347 13.5397 11.9579ZM12.0874 9.27891H10.1541L9.39936 21.8597C9.37376 22.2848 9.02126 22.6123 8.60146 22.6123C8.58546 22.6123 8.56896 22.6117 8.55296 22.6107C8.11186 22.584 7.77586 22.2053 7.80206 21.7643L8.60206 8.43091C8.62766 8.00851 8.97746 7.67891 9.40096 7.67891H10.9962L6.97056 2.51471L2.99726 7.67891H4.60096C5.02386 7.67891 5.37376 8.00851 5.39936 8.43091L6.19936 21.7643C6.22546 22.2053 5.88946 22.584 5.44846 22.6107C5.00256 22.6347 4.62816 22.3008 4.60206 21.8597L3.84736 9.27891H1.91406C1.51456 9.27891 1.15186 9.05871 0.967857 8.70401C0.783357 8.34991 0.811658 7.92641 1.04096 7.59951L5.90126 1.28111C6.13376 0.954705 6.53906 0.745605 6.96896 0.745605C7.39886 0.745605 7.80466 0.954107 8.05486 1.30401L12.9365 7.56691C13.1898 7.92641 13.2181 8.34991 13.0335 8.70401C12.8495 9.05871 12.4869 9.27891 12.0874 9.27891Z"
          fill="white"
        />
      </svg>
    </div>
  );
}

export default VectorIcon;
