import React from 'react'


const Footer = () => {
  return (
    <div className='footer-cont res-footer'>
      <p style={{marginTop:"14px",letterSpacing:"2px"}}> All Rights Reserved &copy;2024 </p>
    </div>
  )
}

export default Footer
